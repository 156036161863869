import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Modal, Button, Form } from 'react-bootstrap';
import { migrateEntityOwner } from '../../redux/actions/UserActions/Action';
import * as commonService from "utility/CommonService.js";
import { ENTITY_TYPES } from "../../utility/constants/constants";
import { Select } from "components/shared/ui-components/form-components";
import * as userActions from "../../redux/actions/UserActions";

const MigrateEntityModal = ({ showModal, setShowModal, sourceUser, users, orgID }) => {
  const dispatch = useDispatch();
  const [entityType, setEntityType] = useState('');
  const [destinationUserId, setDestinationUserId] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);

  // get users from logged-in user's organization on select organization users button click
  useEffect(() => {
    if  (showModal === true) {
      dispatch(userActions.getUsers({
        limit: "all",
        organization: orgID
      }));
    }
  }, [dispatch, orgID, showModal])

  const handleSubmit = async () => {
    if (!entityType || !destinationUserId) {
      commonService.forError("Please fill in all fields", "Error");
      return;
    }

    try {
      setIsSubmitting(true);
      const response = await dispatch(migrateEntityOwner({
        entityType: entityType.toLowerCase(),
        sourceUserId: sourceUser.uid,
        destinationUserId: destinationUserId.trim()
      }));

      if (response.value) {
        commonService.forSuccess(response.value.message, "Success");
        setShowModal(false);
      }
    } catch (error) {
      commonService.forError("Failed to migrate entities", "Error");
    } finally {
      setIsSubmitting(false);
    }
  };

  // Filter out the source user from the destination options
  const destinationOptions = users
    .filter(user => user.uid !== sourceUser?.uid)
    .map(user => ({
      name: `${user.email}`,
      id: user.uid
    }));

  return (
    <Modal show={showModal} onHide={() => setShowModal(false)}>
      <Modal.Header closeButton>
        <Modal.Title>Migrate User Entities</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p className="text-danger">
          Warning: This action will migrate all matching entities from {sourceUser?.email} to the specified user.
          THIS ACTION CANNOT BE UNDONE.
        </p>
        <Form>
          <Form.Group className="mb-3">
            <Form.Label>Entity Type</Form.Label>
            <Select
              placeholder="Select Entity Type"
              name="entityType"
              options={[ENTITY_TYPES.APPLICATION, ENTITY_TYPES.PROJECT_V2, ENTITY_TYPES.WORKFLOW, ENTITY_TYPES.DATASET]}
              onChange={(e) => setEntityType(e.value)}
            />
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label>Destination User</Form.Label>
            <Select
              placeholder="Select Destination User"
              name="destinationUserId"
              options={destinationOptions}
              onChange={(e) => setDestinationUserId(e.value)}
            />
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={() => setShowModal(false)}>
          Cancel
        </Button>
        <Button
          variant="primary"
          onClick={handleSubmit}
          disabled={isSubmitting}
        >
          {isSubmitting ? 'Migrating...' : 'Migrate Entities'}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default MigrateEntityModal;